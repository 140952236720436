<template>
  <div class="relative h-full min-h-screen">
    <TheNavBar />
    

    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
export default {
  components: { TheNavBar },
};
</script>

<style>
* {
  /* font-family: 'Press Start 2P', monospace; */
  font-family: satoshi-black;
  src: url(./assets/fonts/satoshi-black.otf);
}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
