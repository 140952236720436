import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71f89bef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wallet-section" }
const _hoisted_2 = { class: "wallet-box with-title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "flex flex-wrap wallet-inner-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTCard = _resolveComponent("NFTCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
          return (_openBlock(), _createBlock(_component_NFTCard, {
            key: nft,
            nft: nft,
            onSelected: _ctx.handleSelected
          }, null, 8, ["nft", "onSelected"]))
        }), 128))
      ])
    ])
  ]))
}