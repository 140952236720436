import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-1 card flex justify-center", { 'card-selected': _ctx.selected }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.nft.onchainMetadata.data.name), 1)
  ], 2))
}