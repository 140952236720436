import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/play.png'


const _withScopeId = n => (_pushScopeId("data-v-73c7724d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", { disabled: _ctx.disabled }, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "right",
      width: 60,
      height: 60,
      class: _normalizeClass({ left: _ctx.left, disabled: _ctx.disabled })
    }, null, 2)
  ], 8, _hoisted_1))
}